@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

      img.img-fixer {
        width: 26px;
        margin-right: 12px;
      }
      img.img-fixer2 {
        width: 35px;
        margin-right: 12px;
      }
      .panel-heading.custom-bg {
        display: flex;
        align-items: center;
      }
      form.form-data {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .panel-block:not(:last-child),
      .panel-tabs:not(:last-child) {
        border-bottom: 0px;
      }
      a.panel-block:hover,
      label.panel-block:hover {
        background-color: #f5f5f5;
        /* border-radius: 0px 50px 50px 0px; */
      }
      .head {
        display: flex;
      }
      .logo-scale {
        max-width: 190px;
      }
      .head {
        max-width: 1190px;
        margin: 0 auto;
      }
      .nav {
        max-width: 100%;
        width: 100%;
      }
      .head {
        min-height: 92px;
        display: flex;
        align-items: center;
      }
      .navbar-end a {
        color: #162748;
        font-size: 16px;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
      }

      .spacer-1 {
        margin-bottom: 2rem !important;
      }
      .copy i {
        font-size: 18px;
        margin-top: 12px;
      }
      textarea#shareCaption {
        width: 100%;
        min-height: 215px;
        padding: 12px;
      }
      button.btn.copy__btn {
        float: right;
        margin-top: 12px;
      }
      p.panel-heading.bg-pannel {
        background: linear-gradient(180deg, #4f61ff 0%, #7454f4 100%);
      }
      button.button.is-link {
        background: linear-gradient(180deg, #4f61ff 0%, #7454f4 100%);
      }
      a.panel-block {
        padding-top: 16px;
        padding-bottom: 16px;
      }
      a.panel-block.active {
        background: #d3e3fd;
        /* border-radius: 0px 50px 50px 0px; */
      }

      .columns.footer {
        max-width: 1190px;
        margin: 0 auto;
        background-color: #242424;
        font-size: 16px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        color: #fff;
        text-align: center;
        padding: 1rem 0px;
      }

      .foot-b {
        background-color: #242424;
      }
      .footer-logo {
        max-width: 180px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      .spacing-main {
        padding: 5% 3% 5% 3%;
      }
      .width-2 {
        max-width: 100%;
        line-height: initial;
        font-size: 14px;
      }
      .navbar-item,
      .navbar-link {
        padding: 0.5rem 0.99rem;
      } 